import React, { useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"
import Slider from "react-slick"
import "../../../../static/slick/slick-theme.css"
import "../../../../static/slick/slick.css"
import * as theme from "theme"

// Components
import Edges from "../../../components/edges"
import ArrowLeft from "../../../../static/images/icons/arrow-left-carousel1.svg"
import ArrowRight from "../../../../static/images/icons/arrow-right-carousel1.svg"
import ArrowButton from "../../../../static/images/icons/arrow-button.svg"
import Link from "../../../components/link"
import RoundedButton from "../../../components/menu/RoundedButton"
import Button from "../../../components/button"

export default function WorkingWithCedarTemplate(props) {
  const {
    data: {
      wpPage: {
        templateWorkingWithCedar: { module1, module2, module3, module4, module5, wwcHideSearch }
      }
    }
  } = props

  //slider 1
  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: module1.cards.length > 0 ? true : false,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  }

  const sliderRef = useRef()

  const nextSlide = () => {
    sliderRef.current.slickNext()
  }
  const prevSlide = () => {
    sliderRef.current.slickPrev()
  }

  //slider 2
  const sliderSettings2 = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: module3.carouselCards.length > 0 ? true : false,
    autoplaySpeed: 7000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerMode: false
        }
      }
    ]
  }
  const sliderRef2 = useRef()

  const nextSlide2 = () => {
    sliderRef2.current.slickNext()
  }
  const prevSlide2 = () => {
    sliderRef2.current.slickPrev()
  }

  //images
  const module2Image = module2.image && getImage(module2.image.localFile)
  const module5Image = module5.image && getImage(module5.image.localFile)

  return (
    <>
      <ButtonBox>
        <ButtonSticky>
          {module4.retailerButton && <Typography variant="body2" children="Ready To Build?" />}
          {module4.retailerButton && module4.retailerButton.title && (
            <StyledButton color="white" to={module4.retailerButton.url}>
              {Parser(module4.retailerButton.title)}
              <ArrowButton />
            </StyledButton>
          )}
        </ButtonSticky>
      </ButtonBox>
      <Container wwcHideSearch={wwcHideSearch}>
        {/*Module 1---------------------------------------------------------------------------------------- */}
        <Module1Container>
          <div className="arrow-right-container">
            <ArrowRight onClick={prevSlide} className="" />
          </div>
          <Slider {...sliderSettings} ref={sliderRef}>
            {module1.cards.map((card, index) => {
              const module3Image = card.image && getImage(card.image.localFile)
              return (
                <div key={index}>
                  <CardContainer>
                    {module3Image && <GatsbyImage image={module3Image} alt={card.image.altText} />}
                    <ContentContainer>
                      <Edges size="lg">
                        {card.headline && <Typography variant="h1" children={Parser(card.headline)} />}
                        {card.subHeadline && <Typography variant="body2" children={Parser(card.subHeadline)} />}
                        <ButtonContainer>
                          {card.button1 && card.button1.title && (
                            <StyledButton color="white" to={card.button1.url}>
                              {Parser(card.button1.title)}
                            </StyledButton>
                          )}
                          {card.button2 && card.button2.title && (
                            <StyledButton color="white" to={card.button2.url}>
                              {Parser(card.button2.title)}
                            </StyledButton>
                          )}
                        </ButtonContainer>
                      </Edges>
                    </ContentContainer>
                  </CardContainer>
                </div>
              )
            })}
          </Slider>
          <div className="arrow-left-container">
            <ArrowLeft onClick={nextSlide} className="" />
          </div>
        </Module1Container>
        {/*Module 2---------------------------------------------------------------------------------------- */}
        <Module2Container>
          {module2Image && <GatsbyImage image={module2Image} alt={module2.image.altText} />}
          <Module2Content>
            {module2.text && <Typography variant="h3" children={Parser(module2.text)} />}

            {module2.button && <RoundedButton title={module2.button.title} to={module2.button.url} />}
          </Module2Content>
        </Module2Container>
        {/*Module 3---------------------------------------------------------------------------------------- */}
        <Module3Container>
          <Module3Content>
            <Module3Text>
              {module3.headline && <Typography variant="h2" children={Parser(module3.headline)} />}
              {module3.text && <Typography variant="body2" children={Parser(module3.text)} />}
              {module3.button && module3.button.title && (
                <StyledButton color="white" to={module3.button.url}>
                  {Parser(module3.button.title)}
                </StyledButton>
              )}
            </Module3Text>
            <Module3Carousel>
              <div className="arrow-right-container-module2">
                <ArrowRight onClick={prevSlide2} className="" />
              </div>
              <Slider {...sliderSettings2} ref={sliderRef2}>
                {module3.carouselCards.map((carouselcard, index) => {
                  const module3Image = carouselcard.image && getImage(carouselcard.image.localFile)
                  return (
                    <CarouselCard3 to={carouselcard.link.url} key={index}>
                      {module3Image && <GatsbyImage image={module3Image} alt={carouselcard.image.altText} />}
                      {carouselcard.link && carouselcard.link.title && (
                        <CarouselButton to={carouselcard.link.url}>
                          <Typography variant="body2" children={Parser(carouselcard.link.title)} />
                        </CarouselButton>
                      )}
                    </CarouselCard3>
                  )
                })}
              </Slider>
              <div className="arrow-left-container-module2">
                <ArrowLeft onClick={nextSlide2} className="" />
              </div>
            </Module3Carousel>
          </Module3Content>
        </Module3Container>
        {/*Module 4---------------------------------------------------------------------------------------- */}
        <Module4Container>
          <Module4Content>
            {module4.crads &&
              module4.crads.map((card, index) => {
                const module4Image = card.image && getImage(card.image.localFile)
                return (
                  <Module4Card key={index}>
                    {module4Image && <GatsbyImage image={module4Image} alt={card.image.altText} />}
                    <Module4CardText>
                      {card.headline && <Typography variant="h2" children={Parser(card.headline)} />}
                      {card.text && <Typography variant="body2" children={Parser(card.text)} />}
                      {card.button && card.button.title && (
                        <StyledButton color="white" to={card.button.url}>
                          {Parser(card.button.title)}
                        </StyledButton>
                      )}
                    </Module4CardText>
                  </Module4Card>
                )
              })}
          </Module4Content>
        </Module4Container>
        {/*Module 5---------------------------------------------------------------------------------------- */}
        <Module5Container>
          {module5Image && <GatsbyImage image={module5Image} alt={module5.image.altText} />}
          <Module5Content>
            {module5.headline && <Typography variant="h2" children={Parser(module5.headline)} />}
            {module5.text && <Typography variant="body2" children={Parser(module5.text)} />}
            {module5.button && module5.button.title && (
              <StyledButton color="white" to={module5.button.url}>
                {Parser(module5.button.title)}
              </StyledButton>
            )}
          </Module5Content>
        </Module5Container>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: ${(props) => (props.wwcHideSearch ? "unset" : "40px")};
`
const ButtonBox = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

// Module 1 Styles
const Module1Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  .gatsby-image-wrapper {
    height: 250px;
    @media (min-width: 450px) {
      height: 400px;
    }
    @media (min-width: 900px) {
      height: 100%;
    }
  }

  .arrow-right-container {
    position: absolute;
    right: 20px;
    bottom: 10px;
    z-index: 2;
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${theme.colors.secondary};
        }
      }
    }
    @media (min-width: 450px) {
      right: 20px;
      bottom: 30px;
    }
  }
  .arrow-left-container {
    position: absolute;
    right: 40px;
    bottom: 10px;
    z-index: 2;
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${theme.colors.secondary};
        }
      }
    }
    @media (min-width: 450px) {
      right: 40px;
      bottom: 30px;
    }
  }
`
const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const ContentContainer = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  h1 {
    color: white;
    padding-bottom: 10px;
    font-size: 18px;
    @media (min-width: 450px) {
      font-size: 30px;
      padding-bottom: 15px;
    }
    @media (min-width: 900px) {
      font-size: 50px;
    }
  }
  p {
    color: white;
    max-width: 530px;
    padding-bottom: 15px;
    line-height: 15px;
    font-size: 12px;
    padding-right: 20px;
    @media (min-width: 450px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const StyledButton = styled(Button)`
  && {
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 10px 20px;
    @media (min-width: 900px) {
      padding: 15px 24px;
    }
    border-radius: 30px;
    background: ${(props) => props.color};
    color: black;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    border: 1px solid white;
    &:hover:before {
      background: "#232323";
    }
    svg {
      margin-left: 10px;
    }
    span {
      transform: translateY(1px);
    }
  }
`
// Module 2 Styles
const Module2Container = styled.div`
  width: 95%;
  margin: 2.5% 2.5% 0% 2.5%;
  position: relative;
  height: 95%;
  .gatsby-image-wrapper {
    height: 400px;
    @media (min-width: 450px) {
      height: 400px;
    }
    @media (min-width: 900px) {
      height: 100%;
    }
  }
`
const Module2Content = styled.div`
  position: absolute;
  right: 0;
  bottom: 20%;
  background: none;
  height: 60%;
  width: 100%;
  @media (min-width: 540px) {
    width: 50%;
    height: auto;
    bottom: 0;
    background: ${theme.colors.lightgrey};
  }
  /* @media (min-width: 900px) {
    width: 60%;
  }
  @media (min-width: 1040px) {
    width: 50%;
  } */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 30px;
  h3 {
    text-transform: unset;
    padding-bottom: 30px;
    color: white;
    @media (min-width: 540px) {
      color: unset;
    }
  }
`

// Module 3 Styles
const Module3Container = styled.div`
  width: 95%;
  margin: 2.5% 2.5% 0% 2.5%;
  position: relative;
  height: 95%;
  background: #2d312c;
`
const Module3Content = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`
const Module3Text = styled.div`
  width: 100%;
  padding: 30px;
  @media (min-width: 450px) {
    width: 100%;
  }
  @media (min-width: 900px) {
    width: 30%;
  }
  h2 {
    color: white;
    padding-bottom: 15px;
  }
  p {
    color: white;
    padding-bottom: 30px;
  }
`
const Module3Carousel = styled.div`
  width: 100%;
  padding: 30px 50px;
  position: relative;
  @media (min-width: 450px) {
    width: 100%;
  }
  @media (min-width: 900px) {
    width: 70%;
  }
  position: "relative";
  .slick-list {
    padding: 0 !important;
  }
  .slick-slide {
    position: relative;
  }
  .gatsby-image-wrapper {
    max-height: 400px;
    width: 100%;
    @media (min-width: 640px) {
      width: 90%;
    }
  }
  .arrow-right-container-module2 {
    position: absolute;
    right: 20px;
    top: 50%;
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${theme.colors.secondary};
        }
      }
    }
  }
  .arrow-left-container-module2 {
    position: absolute;
    left: 20px;
    top: 50%;
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${theme.colors.secondary};
        }
      }
    }
  }
`
const CarouselCard3 = styled(Link)`
  &:hover {
    p {
      color: ${theme.colors.secondary};
    }
  }
`
const CarouselButton = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 3;
  padding-right: 10px;
  p {
    color: white;
  }
  &:hover {
    p {
      color: ${theme.colors.secondary};
    }
  }
`

// Module 4 Styles
const Module4Container = styled.div`
  width: 95%;
  margin: 2.5% 2.5% 0% 2.5%;
  position: relative;
  height: 95%;
`
const Module4Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const Module4Card = styled.div`
  width: 100%;
  background: ${theme.colors.lightgrey};
  margin-bottom: 20px;
  @media (min-width: 640px) {
    width: 49%;
    margin-bottom: unset;
  }
`
const Module4CardText = styled.div`
  padding: 30px;
  h2 {
    padding-bottom: 15px;
    color: ${theme.colors.primary};
    font-weight: 900 !important;
  }
  p {
    padding-bottom: 20px;
  }
`
const ButtonSticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: calc(100% - 90px);
  bottom: 90px;
  width: 280px;
  @media (min-width: 370px) {
    width: 280px;
    top: calc(100% - 90px);
    bottom: 90px;
  }
  background: ${theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  button {
    margin: 0 !important;
  }
  p {
    color: black;
    font-size: 14px;
    font-weight: 700 !important;
    max-width: 110px;
    text-align: center;
  }
  button {
    padding: 10px 15px !important;
  }
  right: 350px;
  left: calc(100% - 370px);
`
// Module 5 Styles
const Module5Container = styled.div`
  width: 95%;
  margin: 2.5% 2.5% 2.5% 2.5%;
  height: 400px;
  position: relative;
  height: 95%;
  .gatsby-image-wrapper {
    height: 450px;
    @media (min-width: 450px) {
      height: 400px;
    }
    @media (min-width: 900px) {
      height: 100%;
    }
  }
`
const Module5Content = styled.div`
  position: absolute;
  left: 0;
  bottom: 5%;
  background: ${theme.colors.lightgrey};
  height: auto;
  width: 95%;
  padding: 30px;
  @media (min-width: 450px) {
    width: 80%;
    bottom: 10%;
    top: 10%;
  }
  @media (min-width: 1000px) {
    width: 60%;
    bottom: 15%;
    top: 15%;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h2 {
    text-transform: unset;
    padding-bottom: 20px;
    @media (min-width: 450px) {
    }
    @media (min-width: 900px) {
      padding-bottom: 30px;
    }
  }
  p {
    padding-bottom: 20px;
    @media (min-width: 450px) {
    }
    @media (min-width: 900px) {
      padding-bottom: 30px;
    }
  }
  padding-bottom: 50px;

  @media (min-width: 520px) {
    padding-bottom: unset;
  }
`
export const CollectionQuery = graphql`
  query WorkingWithCedarTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSearch
      }
      templateWorkingWithCedar {
        wwcHideSearch
        module1 {
          cards {
            headline
            subHeadline
            button2 {
              target
              title
              url
            }
            button1 {
              title
              target
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        module2 {
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
              }
            }
          }
          button {
            target
            title
            url
          }
        }
        module3 {
          text
          headline
          fieldGroupName
          button {
            target
            title
            url
          }
          carouselCards {
            fieldGroupName
            link {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        module4 {
          retailerButton {
            url
            title
            target
          }
          crads {
            text
            headline
            button {
              url
              title
              target
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        module5 {
          text
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
              }
            }
          }
          button {
            target
            title
            url
          }
        }
      }
    }
  }
`
